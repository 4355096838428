import "./Footer.css";
import LinkedIn from "@material-ui/icons/LinkedIn";
function Footer() {
  return (
    <footer className="footer">
      <div className="footer_info">
        <div className="footer_col">
          <h2>Our Address</h2>
          <ul>
            <li>61st Beach Road Alexandria Egypt</li>
          </ul>
        </div>
        <div className="footer_col">
          <h2>Contact us at</h2>
          <ul>
            <li>
              <a href="tel:+971508011530">+971508011530</a>
            </li>
            <li>
              <a href="mailto:management@flowtechtics.com">
                management@flowtechtics.com
              </a>
            </li>
          </ul>
        </div>
        <div className="footer_col">
          <h2>Social media</h2>
          <ul>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.linkedin.com/company/flowtechtics/"
              >
                <LinkedIn />
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="copyRights">
        <hr />
        <p>Flowtechtics. &copy; 2021. All Rights Reserved</p>
      </div>
    </footer>
  );
}

export default Footer;
