import axios from "axios";
import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import Business from "@material-ui/icons/Business";
import TrendingFlat from "@material-ui/icons/TrendingFlat";
import "./Contact.css";

function Contact() {
  const [user, setUser] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    message: "",
  });
  const [err, setErr] = useState("");
  const [errColor, setErrColor] = useState({});
  const handleOnChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    const removeErr = setTimeout(() => setErr(""), 5000);
    return () => clearTimeout(removeErr);
  }, [err]);
  const hanleSubmit = async (e) => {
    e.preventDefault();

    let data = new FormData();
    for (const key in user) {
      data.append(key, user[key]);
    }
    await axios
      .post("/contact_form", data)
      .then((res) => {
        console.log(res.data);
        if (res.data.re_status === 201) {
          setUser({
            first_name: "",
            last_name: "",
            email: "",
            phone: "",
            message: "",
          });
          setErr("Success!");
          setErrColor({
            color: "#0f5132",
            backgroundColor: "#d1e7dd",
            borderColor: "#badbcc",
            padding: "20px",
            width: "92%",
          });
        } else {
          setErr(res.data.re_message);
          setErrColor({
            color: "#842029",
            backgroundColor: "#f8d7da",
            borderColor: "#f5c2c7",
            padding: "20px",
            width: "92%",
          });
        }
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="contactPage">
      <div className="contact_details">
        <motion.div
          animate={{
            y: -50,
            opacity: 1,
            transition: {
              duration: 1,
              delay: 1,
            },
          }}
          className="address"
        >
          <div className="icon">
            <Business />
          </div>
          <div className="address_detail">
            <h2>
              Corporate
              <br />
              Headquarters
            </h2>
            <p>
              61st Beach Road
              <br />
              Alexandria
              <br />
              Egypt
            </p>
          </div>
        </motion.div>
        <motion.div
          animate={{
            y: -50,
            opacity: 1,
            transition: {
              duration: 1,
              delay: 2,
            },
          }}
          className="contact_phone companyDetails"
        >
          <p>phone number</p>
          <a href="tel:+971508011530">+971508011530</a>
        </motion.div>
        <motion.div
          animate={{
            y: -50,
            opacity: 1,
            transition: {
              duration: 1,
              delay: 2,
            },
          }}
          className="contact_mail companyDetails"
        >
          <p>SEND AN EMAIL</p>
          <a href="mailto:management@flowtechtics.com">
            management@flowtechtics.com
          </a>
        </motion.div>
      </div>
      <form className="contact_form">
        <motion.div
          animate={{
            y: -50,
            opacity: 1,
            transition: {
              duration: 1,
              delay: 1.5,
            },
          }}
          className="form_intro"
        >
          <div className="light_title">GET IN TOUCH</div>
          <p>
            Contact us to request a quote or to schedule a consultation with our
            team.
          </p>
        </motion.div>
        {err !== "" && <h2 style={errColor}>{err}</h2>}
        <motion.div
          animate={{
            y: -100,
            opacity: 1,
            transition: {
              duration: 1,
              delay: 2,
            },
          }}
          className="allForm"
        >
          <div className="form-two-group">
            <div className="form-group required">
              <label>First name *</label>
              <input
                required
                value={user.first_name}
                placeholder="First Name"
                type="text"
                name="first_name"
                onChange={handleOnChange}
              />
            </div>
            <div className="form-group required">
              <label>Last name *</label>
              <input
                required
                value={user.last_name}
                placeholder="Last Name"
                type="text"
                onChange={handleOnChange}
                name="last_name"
              />
            </div>
          </div>
          <div className="form-two-group">
            <div className="form-group">
              <label>Phone number</label>
              <input
                placeholder="Phone Number"
                type="number"
                value={user.phone}
                onChange={handleOnChange}
                name="phone"
              />
            </div>
            <div className="form-group required">
              <label>E-mail *</label>
              <input
                required
                value={user.email}
                placeholder="E-mail"
                type="email"
                onChange={handleOnChange}
                name="email"
              />
            </div>
          </div>
          <div className="form-group required">
            <label>Message *</label>
            <textarea
              required
              value={user.message}
              placeholder="Message"
              onChange={handleOnChange}
              name="message"
            ></textarea>
          </div>
          <div className="goto">
            <motion.button
              animate={{
                x: 100,
                opacity: 1,
                transition: {
                  duration: 1,
                  delay: 3,
                },
              }}
              style={{ color: "#000" }}
              onClick={hanleSubmit}
            >
              SUBMIT <TrendingFlat />
            </motion.button>
          </div>
        </motion.div>
      </form>
    </div>
  );
}

export default Contact;
